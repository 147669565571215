import wx from 'weixin-js-sdk'
import getWxConfig from '@/api/user'
import Axios from 'axios'
const wxApi = {
  /**
   * [wxRegister 微信Api初始化]
   * @param  {Function} callback [ready回调函数]
   */
  wxRegister(res) {

    wx.config({
      debug: false, // 开启调试模式
      timestamp: res.timestamp,
      signature: res.signature,
      appId: res.appId,
      nonceStr:res.nonceStr,
      jsApiList: [
        'updateAppMessageShareData', 'updateTimelineShareData', 'scanQRCode', 'getLocation', 'openLocation',
        'chooseImage', 'previewImage', 'getLocalImgData'
      ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      openTagList: ["wx-open-launch-weapp"]
    })

    wx.ready((res) => {
      // 如果需要定制ready回调方法
      // if (callback) {
      //   callback()
      // }
      console.log('ssq成功了：'+res)
    })

    wx.error(function (res) {
      console.log('ssq失败了1：'+res)
      console.log(res)
      console.log('ssq失败了2：'+res)
      // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名
    });

  },
  /**
   * [ShareTimeline 微信分享到朋友圈]
   * @param {[type]} option [分享信息]
   * @param {[type]} success [成功回调]
   * @param {[type]} error   [失败回调]
   */
  ShareTimeline(option) {
    wx.onMenuShareTimeline({
      title: option.title, // 分享标题
      link: option.link, // 分享链接
      imgUrl: option.imgUrl, // 分享图标
      success() {
        // 用户成功分享后执行的回调函数
        option.success()
      },
      cancel() {
        // 用户取消分享后执行的回调函数
        option.error()
      }
    })
  },
  /**
   * [ShareAppMessage 微信分享给朋友]
   * @param {[type]} option [分享信息]
   * @param {[type]} success [成功回调]
   * @param {[type]} error   [失败回调]
   */
  ShareAppMessage(option) {
    wx.onMenuShareAppMessage({
      title: option.title, // 分享标题
      desc: option.desc, // 分享描述
      link: option.link, // 分享链接
      imgUrl: option.imgUrl, // 分享图标
      success() {
        // 用户成功分享后执行的回调函数
        option.success()
      },
      cancel() {
        // 用户取消分享后执行的回调函数
        option.error()
      }
    })
  }
}
export default wxApi
